<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            v-if="orders.length > 0"
            elevation="9"
            rounded
            color="grey"
            @click="$router.go(-1)"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>

          <v-btn
            elevation="9"
            rounded
            color="grey"
            @click="$router.push('/store/order-return/' + $route.params.id)"
          >
            New Order Return
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">Order Return List</h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-data-table
                      :headers="headers"
                      :items="vendor_order_returns"
                      sort-by="name"
                      class="elevation-1"
                    >
                      <template #top>
                        <v-toolbar flat>
                          <v-toolbar-title>Returns</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template #item="{ item }">
                        <tr>
                          <td>
                            {{ item.id }}
                          </td>
                          <td>
                            {{ item.order_id }}
                          </td>
                          <td>
                            {{ item.created_at | date_format }}
                          </td>
                          <td>
                            {{ item.company_name }}
                          </td>
                          <td>
                            {{ item.user_name }}
                          </td>
                          <td>
                            <span v-if="item.status != null">
                              {{ item.status }}
                            </span>
                            <span v-else> Request </span>
                          </td>
                          <td>
                            <v-icon
                              small
                              class="mr-2"
                              @click="
                                $router.push('/store/return-detail/' + item.id)
                              "
                            >
                              mdi-eye
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>

                    <!--end: Datatable-->
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    headers: [
      { text: "Return ID" },
      { text: "Order ID" },
      { text: "Date" },
      { text: "Business Name" },
      { text: "User" },
      { text: "Status" },
      { text: "Actions", sortable: false },
    ],
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    // vendor_order_returns: {
    //   client: "ecomClient",
    //   query: gql`
    //     query OrderReturns($vendor_order_id: Int) {
    //       vendor_order_returns(vendor_order_id: $vendor_order_id) {
    //         id
    //         order_id
    //         created_at
    //         status
    //         total
    //         company_name
    //         user_name
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       vendor_order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
  },

  created() {
    this.getVendorOrderDetail();
  },
  methods: {
    isInt(n) {
      return n % 1 === 0;
    },
    async updateReturn() {
      let flag = 0,
        item_arr = [],
        isZero = 1;
      for (let i in this.orders) {
        if (!this.isInt(parseFloat(this.orders[i].qty_to_return))) {
          flag = 1;
        }
        if (
          parseInt(this.orders[i].qty_to_return) > parseInt(this.orders[i].qty)
        ) {
          flag = 1;
        }
        if (parseInt(this.orders[i].qty_to_return) != 0) {
          isZero = 0;
        }
        item_arr.push(this.orders[i].id + "-" + this.orders[i].qty_to_return);
      }
      if (flag == 1) {
        Swal.fire({
          title: "",
          text: "Invalid qty",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      } else if (isZero == 1) {
        Swal.fire({
          title: "",
          text: "Empty",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      } else {
        // let res = await this.$apollo.mutate({
        //   client: "ecomClient",
        //   mutation: gql`
        //     mutation AddReturnMutation($order_id: Int!, $item_str: String!) {
        //       addReturn(order_id: $order_id, item_str: $item_str)
        //     }
        //   `,
        //   variables: {
        //     order_id: parseInt(this.$route.params.id),
        //     item_str: item_arr.join("_"),
        //   },
        // });
        // if (res.data.addReturn == true) {
        //   Swal.fire({
        //     title: "",
        //     text: "Updated successfully.",
        //     icon: "success",
        //     confirmButtonClass: "btn btn-secondary",
        //   });
        //   this.$router.push(
        //     "/store/order-detail/" + this.orders[0].main_order_id
        //   );
        // }
      }
    },
    async getVendorOrderDetail() {
      await this.$apollo.queries.vendor_order_returns.refetch();
      let order_detail = this.vendor_order_returns;
      this.orders = order_detail;
    },
  },
};
</script>
